import React, {useState, useRef} from "react"
import styled from "styled-components"
import { Transition } from 'react-transition-group'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import Layout from "../components/layout"
import SEO from "../components/seo"

import Link, { AnalyticsExternalLink } from '../components/analytics/analyticslink';
import ScrollAnalytics from '../components/analytics/scrollanalytics';
import Section, { SectionWrapper } from '../components/section';
import DotBackground from '../components/dotbackground';
import Frame from '../components/frame';
import Button from '../components/button';
import RoundButton from '../components/roundbutton';
import CarouselHeader from '../components/carouselheader';
import MobileScreenshot from '../components/mobilescreenshot';
import MobileVideo from '../components/mobilevideo';
import LaptopVideo from '../components/laptopvideo';
import TabletVideo from '../components/tabletvideo'; 
import ScrollDown from '../components/scrolldown';
import { dimensions } from '../shared/styles';

import Contacts from '../sections/contacts';
import Customers from '../sections/customers';

// import mobileIconUrl from '../images/mobile-icon.svg';
// import webIconUrl from '../images/web-icon.svg';
// import ctoIconUrl from '../images/cto-icon.svg';
import Abstrait   from '../components/svg/abstrait';
import MobileIcon from '../components/svg/mobile';
import WebIcon    from '../components/svg/web';
import CtoIcon    from '../components/svg/cto';

import content from '../data/static-content.js';
import references from '../data/references';

import { behaviourTag } from '../shared/analytics';

/* ---------------------------------------------- */
/* ---------------------------------------------- */

const MainSectionWrapperComponent = styled(SectionWrapper)`
  height : 100%;

  @media screen and (max-width : 425px) {
    height : 200%;
  }
`;

const MainSection = styled(Section).attrs(() => ({
  WrapperComponent : MainSectionWrapperComponent
}))`
  height : 100%;
  padding-top : ${dimensions.headerHeight};
  margin-bottom : 15rem;
  position : relative;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : flex-start;

  @media screen and (max-width : 425px) {
    height : 100%;
    display : flex;
    flex-direction : column-reverse;
    padding-top : 0;
  }
`;


const MainSectionBackground = styled(DotBackground)`
  position : absolute;
  left     : 20%;
  width    : 70%;
  height   : 100%;

  @media screen and (max-width: 425px) {
    top : 50%;
    height : 50%;
  }
`;

const MainSectionFrameContainer = styled.div`
  width : 45%;
  margin-left : 10%;

  @media screen and (max-width: 425px) {
    width : 80%;
    margin-left : 10%;
    margin-right : 10%;
    flex 1;
    display : flex;
    flex-direction : column;
    justify-content : center;
  }
`;

const AbstraitContainer = styled.div`
  position : relative;
  flex : 1;
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;

  @media screen and (max-width: 425px) {
    width : 100%;
    height : 100%;
  }
`;

const MainSectionFrame = styled(Frame)`

`;

const MainSectionDescription = styled.div`
  line-height : 3rem;
`

const MainSectionButton = styled(Button)`
  position : absolute;
  right    : -70px;

  @media screen and (max-width: 425px) {
    right : auto;
    left : 50%;
    transform : translateX(-50%)
  }
`;

const PositionedAbstrait = styled(Abstrait)`
  width : 29.6rem;
  height : 46.6rem;
  margin-left : auto;
  margin-right : auto;
  position : relative;

  @media screen and (max-width: 800px) {
    width : 70%;
    height : 70%;
  }
`;

/* ---------------------------------------------- */
/* ---------------------------------------------- */

const ActivitiesSection = styled(Section).attrs(props => ({
  wrapperStyle : {
    backgroundColor : '#252F3A'
  }
}))`
  width  : 100%;
  padding-top : 6.2rem;
  padding-bottom : 6.2rem;
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;
`;

const ActivitiesSectionContent = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : space-between;
  max-width : 110rem;
  width : 100%;
  margin-left : 5%;
  margin-right : 5%;

  @media screen and (max-width: 425px) {
    flex-direction : column;
    align-items : center;
  }
`;

const Activity = styled.div`
  max-width : 30rem;
  margin-left : 3%;
  margin-right : 3%;

  @media screen and (max-width: 425px) {
    text-align : center;
    margin-top : 5.4rem;
  }
`;

const ActivityName = styled.h3`
  font-weight : 800;
  font-size : 2.2rem;
  color : white;
  padding-top : 3.5rem;

  @media screen and (max-width: 425px) {
    text-align : center;
    padding-top : 2.5rem;
  }
`;

const ActivityDescription = styled.div`
  font-size : 1.8rem;
  color : white;
  padding-top : 1.3rem;
  line-height : 3rem;
`;

/* ---------------------------------------------- */
/* ---------------------------------------------- */


const MethodSection = styled.div`
  background-color : white;
  padding-top     : 12rem;
  padding-bottom  : 12rem;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  overflow        : hidden;
`;

const MethodRow = styled.div`
  display         : flex;
  flex-direction  : row;
  justify-content : center;
  width           : 100rem;
  flex-wrap       : wrap;

  @media screen and (max-width: 800px) {
    width : 90rem;
  }
  @media screen and (max-width: 425px) {
    width : 60rem;
  }
`

const MethodTile = styled(Frame).attrs(props => ({
  contentStyle : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'center',
    alignItems : 'center',
    paddingLeft : 0,
    paddingRight : 0
  }
}))`
  width : 29rem;
  height : 10rem;
  margin-right : 3.5rem;
  margin-bottom : 5.5rem;
  flex-shrink : 0;
`;

const MethodLink = styled(Link)`
  font-weight : 800;
  font-size : 3rem;
  color : black;
  text-decoration : none;

  @media screen and (min-width : 426px) and (max-width: 800px) {
    &:nth-child(2), &:nth-child(6) {
      margin-right : 10rem;
      margin-left : 3.5rem;
    }
    &:nth-child(3) {
      margin-left : 10rem;
      margin-right : 7rem;
    }
  }
  @media screen and (max-width: 425px) {
    &:nth-child(1) {
      margin-right : 0rem;
    }
    &:nth-child(2) {
      margin-right : 10rem;
    }
    &:nth-child(3) {
      margin-left : 10rem;
    }
    &:nth-child(5) {
      margin-left : 10rem;
    }
    &:nth-child(6) {
      margin-right : 7rem;
    }
  }

`;

const ResponsiveTabletVideo = styled(TabletVideo)`
position : absolute;
transform : scale(0.7) rotate(-10deg);

@media screen and (max-width : 1000px) {
  transform : scale(0.65);
}

@media screen and (max-width : 800px) {
  transform : scale(0.5);
}

@media screen and (max-width : 600px) {
  transform-origin: 40% 50%;
}

@media screen and (max-width : 425px) {
  position : relative;
  transform-origin: center right; 
}
`;

const RoundLinkContainer = styled.div`
  border-bottom : 1px solid rgba(1,1,1,0.1);
  height : 3.5rem;
  margin-bottom : 13rem;
  display : flex;
  flex-direction : row;
  justify-content : flex-end;
  padding-right : 15rem;

  @media screen and (max-width: 800px) {
    padding-right : 5rem;
  }
`;

/* ---------------------------------------------- */
/* ---------------------------------------------- */


const ReferencesSection = styled.div`
  position         : relative;
  background-color : white;
  padding-top      : 11rem;
  padding-bottom   : 11rem;
  overflow         : hidden;
`;

const ReferencesContainer = styled.div`
  height : 70rem;
`;

const ReferencesSectionBackground = styled(DotBackground)`
  position : absolute;
  left     : 10%;
  top      : 60rem;
  width    : 50%;
  height   : 100%;
`;


const ReferenceContainer = styled.div.attrs(({state}) => ({
  style :
    state === 'entering' || state === 'entered' ? {opacity : 1} :
    state === 'exiting' ? {opacity : 0} :
    {opacity : 0, display : 'none'}
}))`
  position : absolute;
  width : 100%;
  height : 60rem;
  transition : opacity 0.3s linear;
  display : flex;
  flex-direction : row;
  justify-content : flex-start;
`;

const ScreenshotContainer = styled.div`
  padding-top : 9.45rem;
  width : 50%;
  display : flex;
  flex-direction : row;
  justify-content : space-around;

  @media screen and (max-width: 425px) {
    flex-direction : row;
    justify-content : flex-end;
    padding-right : 3rem;
    width : 48%;
  }
`;

const ReferencesLinkContainer = styled.div`
  padding-left : 50%;
  margin-top : -15rem;

  @media screen and (max-width: 425px) {
    padding-left : 0;
    margin-top : 0;
  }
`;

const ReferenceDescriptionContainer = styled.div`
  padding-top : 9.45rem;
  width : 50%;
`;

const ReferenceDescription = styled.p`
  margin-right : 35%;
  line-height : 3rem;
`;

const ReferenceHashTag = styled.div`
  display : inline-block;
  margin-right : 1.7rem;
  padding : 1rem 2rem;
  border : 3px solid #525DF4;
  background-color : rgba(82, 83, 244, 0.1);
  font-size : 1.6rem;
  font-weight : 800;
  color : #525DF4;
  margin-top : 3rem;
`;

const CroppedMobileScreenshot = styled(MobileScreenshot)`
  margin-top : -6rem;
  flex : 0;
  flex-basis : 28.9rem;
  transform : rotate(-5deg) scale(0.85);

  @media screen and (max-width: 800px) {
    display : none;
  }
`;

const ResponsiveMobileVideo = styled(MobileVideo)`
`

const ResponsiveLaptopVideo = styled(LaptopVideo)`
  transform : scale(0.7);

  @media screen and (max-width : 1000px) {
    transform : scale(0.65);
  }

  @media screen and (max-width : 800px) {
    transform : scale(0.5);
  }

  @media screen and (max-width : 600px) {
    transform-origin: 40% 50%;
  }

  @media screen and (max-width : 425px) {
    transform-origin: center right;
  }
`;

const IndexPage = () => {

  const [selectedReference, setSelectedReference] = useState(0);

  const [abstraitCollapsed, setAbstraitCollapsed] = useState(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const collapsed = currPos.y <= -30

      setAbstraitCollapsed(collapsed);
    },
    [abstraitCollapsed]
  );

  return (
    <Layout
      headerProps={{animation : 'home'}}
    >
      <SEO title="Appunto" />
      <MainSection>
        <MainSectionBackground />
        <MainSectionFrameContainer id="main">
          <MainSectionFrame
            bl={{animation:"rotate-ticks"}}
            tl={{animation:"rotate-360", color:"pink"}}
            tr={{animation:"in-out"}}
            contentStyle={{paddingBottom : '25px'}}
          >
            <h1>{content.home.main.title}<br />{content.home.main.titleLine2}</h1>
            <MainSectionDescription>
              {
                content.home.main.description.map((paragraph, i) => <p key={i}>{paragraph}</p>)
              }
            </MainSectionDescription>
            <AnalyticsExternalLink action="home->customers" onClick={() => document.getElementById('scrolling-customers').scrollIntoView({behavior : 'smooth'})}>
              <MainSectionButton>{content.home.main.button}</MainSectionButton>
            </AnalyticsExternalLink>
          </MainSectionFrame>
        </MainSectionFrameContainer>
        <AbstraitContainer>
          <PositionedAbstrait collapsed={abstraitCollapsed}/>
          <ScrollDown onClick={() => document.getElementById('main').scrollIntoView({behavior : 'smooth'})}/>
        </AbstraitContainer>
      </MainSection>

      <div id="offre" style={{position:'relative', top : `-${dimensions.headerHeight}`}}></div>
      <ActivitiesSection>
        <h2 className="center" style={{marginBottom : '8rem', color : 'white'}}>{content.home.activitiesTitle}</h2>
        <ScrollAnalytics action="scroll-home-offer">
          <ActivitiesSectionContent>
            <Activity onClick={() => behaviourTag(`click-offer-${content.home.activities[0].title.replace(' ', '-').toLowerCase()}`)}>
              <CtoIcon/>
              <ActivityName>{content.home.activities[0].title}</ActivityName>
              <ActivityDescription>{content.home.activities[0].description}</ActivityDescription>
            </Activity>
            <Activity onClick={() => behaviourTag(`click-offer-${content.home.activities[1].title.replace(' ', '-').toLowerCase()}`)}>
              <MobileIcon />
              <ActivityName>{content.home.activities[1].title}</ActivityName>
              <ActivityDescription>{content.home.activities[1].description}</ActivityDescription>
            </Activity>
            <Activity onClick={() => behaviourTag(`click-offer-${content.home.activities[2].title.replace(' ', '-').toLowerCase()}`)}>
              <WebIcon />
              <ActivityName>{content.home.activities[2].title}</ActivityName>
              <ActivityDescription>{content.home.activities[2].description}</ActivityDescription>
            </Activity>
          </ActivitiesSectionContent>
        </ScrollAnalytics>
      </ActivitiesSection>

      <ScrollAnalytics>
          <Customers />
      </ScrollAnalytics>

      <ReferencesSection>
        <ReferencesSectionBackground />
        <h2 className="center" style={{marginBottom : '8rem'}}>{content.home.references.title}</h2>
        <CarouselHeader
          choices={references.home.map(({shortName:n}) => n)}
          onSelect={setSelectedReference}
          selected={selectedReference}
          />
        <ScrollAnalytics action="scroll-home-references">
          <ReferencesContainer>
            {
              references.home.map(
                (reference, index) => (
                  <Transition key={reference.shortName} in={index === selectedReference} timeout={300}>
                    {
                      (state) =>
                        <ReferenceContainer key={reference.shortName} state={state}>
                          <ScreenshotContainer>
                            {
                              reference.screenshots.type === 'mobile-image-video' ?
                                <>
                                <CroppedMobileScreenshot
                                  src={reference.screenshots.image}
                                  />
                                <ResponsiveMobileVideo
                                  src={reference.screenshots.video}
                                  />
                                </> :
                              reference.screenshots.type === 'laptop-video' ?
                                <ResponsiveLaptopVideo
                                  src={reference.screenshots.video}
                                /> :
                              reference.screenshots.type === 'tablet-video' ?
                                <ResponsiveTabletVideo
                                  src={reference.screenshots.video}
                                /> :
                                null
                            }
                          </ScreenshotContainer>
                          <ReferenceDescriptionContainer>
                            <h2 style={{paddingTop : '8.3rem'}}>{reference.shortName}</h2>
                            <ReferenceDescription>{reference.description}</ReferenceDescription>
                            <div>
                              {
                                reference.hashtags.map(
                                  ht => <ReferenceHashTag key={ht}>#{ht}</ReferenceHashTag>
                                )
                              }
                            </div>
                          </ReferenceDescriptionContainer>
                        </ReferenceContainer>
                    }
                  </Transition>
                )
              )
            }
          </ReferencesContainer>
        </ScrollAnalytics>
        <ReferencesLinkContainer>
          <RoundLinkContainer style={{marginTop : '4rem'}}>
            <Link action="home->references" to="/references"><RoundButton type="button">{content.home.references.button}</RoundButton></Link>
          </RoundLinkContainer>
        </ReferencesLinkContainer>
      </ReferencesSection>

      <MethodSection>
        <h2 className="center" style={{marginBottom : '8rem'}}>{content.home.method.title}</h2>
        <ScrollAnalytics action="scroll-home-expertise">
          <MethodRow>
            {
              [
                {animation:"rotate-ticks"},
                {animation:"in-out", color:"pink"},
                {animation:"rotate"},
                {animation:"rotate-back", color:"pink"},
                {animation:"rotate-360"},
                {animation:"rotate-ticks-fast", color:"pink"}
              ].map(
                (cross, index) =>
                  <MethodLink
                    to={'/expertise#'+content.method.tiles[index].hash}
                    action={`home->${content.method.tiles[index].hash}`}
                    key={index}
                    >
                      <MethodTile key={index} tr={cross} collapseOnHover>
                      {content.method.tiles[index].title}
                    </MethodTile>
                  </MethodLink>
              )
            }
          </MethodRow>
        </ScrollAnalytics>
      </MethodSection>

      <RoundLinkContainer>
        <Link action="home->expertise" to="/expertise"><RoundButton>{content.home.method.button}</RoundButton></Link>
      </RoundLinkContainer>
      <ScrollAnalytics action="scroll-home-contact">
        <div id="contact-section">
          <Contacts />
        </div>
      </ScrollAnalytics>
      <script>
        {`
          if (window.netlifyIdentity) {
            window.netlifyIdentity.on("init", user => {
              if (!user) {
                window.netlifyIdentity.on("login", () => {
                  document.location.href = "/admin/";
                });
              }
            });
          }
        `}
      </script>
    </Layout>
  );
}


export default IndexPage
